@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    font-size: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.font-inter {
    font-family: 'Inter', sans-serif;
}

/* @font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
} */

.font-ryomen {
    font-family: 'Ryomen', sans-serif;
}

@font-face {
    font-family: 'Ryomen';
    src: url('../public/fonts/Ryomen.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* public/fonts/cygre/Cygre-Black.ttf public/fonts/cygre/Cygre-BlackIt.ttf public/fonts/cygre/Cygre-Bold.ttf public/fonts/cygre/Cygre-BoldIt.ttf public/fonts/cygre/Cygre-Book.ttf public/fonts/cygre/Cygre-BookIt.ttf public/fonts/cygre/Cygre-ExtraBold.ttf public/fonts/cygre/Cygre-ExtraBoldIt.ttf public/fonts/cygre/Cygre-Light.ttf public/fonts/cygre/Cygre-LightIt.ttf public/fonts/cygre/Cygre-Medium.ttf public/fonts/cygre/Cygre-MediumIt.ttf public/fonts/cygre/Cygre-Regular.ttf public/fonts/cygre/Cygre-RegularIt.ttf public/fonts/cygre/Cygre-SemiBold.ttf public/fonts/cygre/Cygre-SemiBoldIt.ttf public/fonts/cygre/Cygre-Thin.ttf public/fonts/cygre/Cygre-ThinIt.ttf */


.font-cygre {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-black {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-blackit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-bold {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-boldit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-book {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-bookit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-extrabold {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-extraboldit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-light {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-lightit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-medium {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-mediumit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-regular {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-regularit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-semibold {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-semiboldit {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-thin {
    font-family: 'Cygre', sans-serif;
}

.font-cygre-thinit {
    font-family: 'Cygre', sans-serif;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-BlackIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-BoldIt.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-BookIt.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-ExtraBoldIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-RegularIt.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-SemiBoldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/cygre/Cygre-ThinIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}